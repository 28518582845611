<template>
    <div>
        <a-form :form="form" @submit.prevent="handleSubmit">
            <a-row type="flex" :gutter="24">
                <a-col :span="24" :lg="24" class="mb-24">
                    <a-card :bordered="false" class="header-solid h-full"
                        :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                        <template #title>
                            <h5 class="mb-0">Admin details</h5>
                        </template>
                        <a-row :gutter="[36]">
                            <a-col :span="6">
                                <a-form-item label="First Name" :colon="false" required>
                                    <a-input v-model="admin.first_name" placeholder="eg. John" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="Last Name" :colon="false" required>
                                    <a-input v-model="admin.last_name" placeholder="eg. Doe" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="Email" :colon="false" required>
                                    <a-input v-model="admin.email" placeholder="eg. example@octripus.com" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="Password" :colon="false" required>
                                    <a-input v-model="admin.password" placeholder="eg. Password" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="Birth Date" :colon="false">
                                    <a-date-picker v-model="admin.birth_date" format="DD/MM/YYYY"
                                        placeholder="Select Date" style="width: 100%;" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="Select Role" :colon="false" required>
                                    <a-select v-model="admin.role" :colon="false" required>
                                        <a-select-option v-for="role in toChooseRole" :key="role.name"
                                            :value="role.name">
                                            {{ role.title }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-card>
                </a-col>
                <a-col :span="24">
                    <a-button type="primary" html-type="submit"> Submit </a-button>
                </a-col>
            </a-row>
        </a-form>
    </div>
</template>

<script>
import adminAPI from "../../api/admin";
import router from "../../router";
import moment from "moment";

export default {
    data() {
        return {
            toChooseRole: [
                {
                    title: "Super Admin",
                    name: "super_admin",
                },
                {
                    title: "Admin",
                    name: "admin",
                },
            ],
            admin: {
                first_name: "",
                last_name: "",
                birth_date: null, // Keep this directly linked to a-date-picker
                email: "",
                password: "",
                role: "",
                created_at: "",
                updated_at: null,
            },
            isEdit: false,
            form: this.$form.createForm(this),
        };
    },
    methods: {
        async handleSubmit() {
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    if (this.admin.birth_date) {
                        this.admin.birth_date = moment(this.admin.birth_date).format('DD/MM/YYYY');
                    }
                    if (!this.isEdit) {
                        console.log(this.admin)
                        try {
                            await adminAPI.createAdmin(this.admin);
                            router.push("/admins/list"); // Redirect after success
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        console.log(this.admin)
                        try {
                            await adminAPI.updateAdmin(this.$route.params.id, this.admin);
                            router.push("/admins/list"); // Redirect after success
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            });
        },
        async getAdminById(id) {
            return adminAPI.getAdminById(id);
        },
    },
    async mounted() {
        if (this.$route.params.id) {
            const adminData = await this.getAdminById(this.$route.params.id);
            if (adminData.code == "SUCCESS") {
                this.admin = {
                    ...adminData.data,
                };
                if (adminData.data.birth_date) {
                    this.admin.birth_date = moment(adminData.data.birth_date);
                } else {
                    this.admin.birth_date = null;
                }
                this.isEdit = true;
                console.log(this.admin)
            }
        }
    },
};
</script>
