import router from "../router";
import client from "./client";
import VueCookies from 'vue-cookies'; // Make sure you have this imported

// Function to update an existing admin by ID
const updateAdmin = (adminId, adminData) => {
    const accessToken = VueCookies.get("access_token");
    if (!accessToken) {
        // Redirect to another page if token is missing
        router.push("/reports/reports-list");
        return;
    }

    return client
        .put(`/admin/${adminId}`, adminData, {
            headers: {
                Authorization: `${accessToken}`, // Add the JWT token here
                'Content-Type': 'application/json', // Set content type to JSON
            },
        })
        .then((response) => response.data)
        .catch((error) => {
            console.error(error.response);
            router.push("/reports/reports-list"); // Redirect on error
        });
};
// Function to create a new admin
const createAdmin = (adminData) => {
    const accessToken = VueCookies.get("access_token");
    if (!accessToken) {
        // Redirect to another page if token is missing
        router.push("/reports/reports-list");
        return;
    }

    return client
        .post(`/admin`, adminData, {
            headers: {
                Authorization: `${accessToken}`, // Add the JWT token here
                'Content-Type': 'application/json', // Set content type to JSON
            },
        })
        .then((response) => response.data)
        .catch((error) => {
            console.error(error.response);
            router.push("/reports/reports-list"); // Redirect on error
        });
};
const getAdminById = (adminId) => {
    const accessToken = VueCookies.get("access_token");
    if (!accessToken) {
        // Redirect to another page if token is missing
        router.push("/reports/reports-list");
        return;
    }

    return client
        .get(`/admin/${adminId}`, {
            headers: {
                Authorization: `${accessToken}`, // Add the JWT token here
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error(error.response);
            router.push("/reports/reports-list"); // Redirect on error
        });
};

const getAdmins = (filter) => {
    const accessToken = VueCookies.get("access_token");
    if (!accessToken) {
        router.push("/reports/reports-list")
    }

    return client
        .get(`/admins`, {
            headers: {
                Authorization: `${accessToken}`, // Add the JWT token here
            },
            params: filter, // Pass filter as query params
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error.response)
            router.push("/reports/reports-list")
        });
};

const adminAPI = {
    getAdmins,
    getAdminById,
    createAdmin,
    updateAdmin,
};

export default adminAPI;


